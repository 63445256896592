
.pagination-item {
  background: #fff;
  width: 40px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.pagination-item.horizontal {
  transform: rotate(-90deg);
  width: 78px;
  min-width: 78px;
  height: 43px;
}
.pagination-item:before {
  content: '';
  position: absolute;
  width: 31px;
  height: 9px;
  background: #fff;
  border-radius: 50%;
  bottom: -11px;
}
.pagination-item.horizontal:before {
  width: 60px;
  height: 17px;
  bottom: -20px;
}
.pagination-item:after {
  content: '';
  position: absolute;
  width: 22px;
  height: 3px;
  background: #fff;
  border-radius: 50%;
  bottom: -16px;
}
.pagination-item.horizontal:after {
  width: 43px;
  height: 5px;
  bottom: -28px;
}
.pagination-item_2 {
  transform: translateY(8px);
}

.pagination-item_2:before {
  bottom: auto;
  top: -11px;
}

.pagination-item_2:after {
  width: 31px;
  height: 9px;
  background: #fff;
  border-radius: 50%;
  bottom: -11px;
}

.pagination-item_3 {
  transform: translateY(18px);
}

.pagination-item_3:before {
  bottom: auto;
  top: -11px;
}

.pagination-item_3:after {
  bottom: auto;
  top: -16px;
}

@media only screen and (max-width: 992px) {
  .pagination-item.horizontal {
    width: 62px;
    min-width: 62px;
    height: 34px;
  }
  .pagination-item.horizontal:before {
    width: 48px;
    height: 13px;
    bottom: -16px;
  }
  .pagination-item.horizontal:after {
    width: 34px;
    height: 4px;
    bottom: -23px;
  }
}

