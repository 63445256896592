footer {
  min-height: 120px;
  background: rgba(217, 217, 217, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
  margin-top: 96px;
  padding: 0 24px;
}
footer a {
  color: var(--muted-color);
  transition: var(--transition);
  cursor: pointer;
  padding: 0 8px;
}
footer a:hover {
  color: var(--text-color);
}