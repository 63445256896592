#section_5 {
  margin-top: 168px;
}

.section_5-text {
  max-width: 827px;
  margin-bottom: 48px;
}

.section_5-form {
  padding: 32px;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  border-radius: 40px;
  border: 1px solid transparent;
}

.section_5-form p {
  margin-bottom: 40px;
}

.section_5-success-form {
  background-color: var(--purple);
  border-radius: 40px;
  padding: 32px;
}

.section_5-success-form-title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.section_5-success-form-logo {
  display: flex;
  justify-content: flex-end;
}

.section_5-success-form-logo path {
  fill: var(--text-color);
}

.section_5-terms {
  padding-left: 40px;
  padding-right: 24px;
  display: flex;
}
@media only screen and (max-width: 992px) {
  #section_5 {
    margin-top: 40px;
  }
  .section_5-text {
    margin-bottom: 24px;
  }
  .section_5-form {
    padding: 16px;
    border-radius: 24px;
  }
  .section_5-form p {
    margin-bottom: 16px;
  }
  #section_5 .pagination-item {
    display: none;
  }
  .section_5-terms {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
  }
}
