header {
  height: 120px;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 28px 100px;
  transition: none;
}

header.scrolling {
  transition: var(--transition);
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(20px);
}

.header-right {
  display: flex;
  position: absolute;
  right: 100px;
  margin-right: calc(-1 * (100vw - 100%));
}
header .logo {
  opacity: 0;
  transition: var(--transition);
}
header .logo path {
  fill: #fff;
}
header.scrolling .logo {
  opacity: 1;
}
header button {
  width: 201px;
  height: 64px !important;
}

.header-modal-btn {
  width: 100%;
  max-width: 100% !important;
}
.recovery-success-text {
  position: absolute;
  top: -24px;
  font-size: 14px;
}

@media only screen and (max-width: 1700px) {
  header {
    padding-left: 40px;
    padding-right: 40px;
  }

  .header-right {
    right: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  header {
    padding-left: 24px;
    padding-right: 24px;
  }

  .header-right {
    right: 24px;
  }
}

@media only screen and (max-width: 992px) {
  header {
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    background: rgba(41, 41, 48, 0.16);
    backdrop-filter: blur(20px);
  }
  header svg{
    height: 32px;

  }
  header button{
    height: 32px !important;
    border-radius: 30px !important;
    font-size: 12px !important;
    width: auto !important;
    min-width: auto !important;
    padding: 0 24px !important;
  }
  header .logo {
    margin-left: -12px;
  }
}

@media only screen and (max-width: 768px) {
  header {
    padding: 0 16px;
  }
  .header-right {
    right: 16px;
  }
}

@media only screen and (max-width: 576px) {

}

@media only screen and (max-width: 375px) {

}